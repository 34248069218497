import { readLocalStorageValue, useLocalStorage } from '@mantine/hooks';
import { OpenEnrollmentPeriodDto } from '@zorro/clients';
import { parseDateISO } from '@zorro/shared/formatters';
import { useMemo } from 'react';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';

import { useMonolithQuery } from './useMonolithQuery';

const findEnrollmentPeriodIdOrDefault = (
  openEnrollmentPeriods: OpenEnrollmentPeriodDto[],
  enrollmentPeriodId: string
) => {
  return (
    openEnrollmentPeriods.find((period) => period.id === enrollmentPeriodId)
      ?.id ||
    (openEnrollmentPeriods.length > 0 ? openEnrollmentPeriods[0].id : undefined)
  );
};

export function useEmployeeDatatableEnrollmentPeriod(employerId: string) {
  const { data: openEnrollmentPeriod = [] } = useMonolithQuery({
    method: 'openEnrollmentPeriodsControllerFindAllForEmployer',
    params: [employerId],
  });

  const enrollmentPeriods = openEnrollmentPeriod.sort((first, second) =>
    parseDateISO(second.effectiveFrom).isAfter(
      parseDateISO(first.effectiveFrom)
    )
      ? 1
      : -1
  );

  const storedOpenEnrollmentPeriodId = readLocalStorageValue<string>({
    key: `selectedOpenEnrollmentPeriodId_${employerId}`,
    defaultValue: '',
  });

  const [openEnrollmentPeriodQuery, setOpenEnrollmentPeriodQuery] =
    useQueryParam('enrollmentPeriodId', withDefault(StringParam, ''));

  const [enrollmentPeriodLocal, setEnrollmentPeriodLocal] = useLocalStorage({
    key: `selectedOpenEnrollmentPeriodId_${employerId}`,
    defaultValue: storedOpenEnrollmentPeriodId,
  });

  const selectedEnrollmentPeriodId = useMemo(() => {
    if (enrollmentPeriods.length > 0 && openEnrollmentPeriodQuery !== '') {
      return findEnrollmentPeriodIdOrDefault(
        enrollmentPeriods,
        openEnrollmentPeriodQuery
      );
    } else if (
      enrollmentPeriodLocal !== '' &&
      openEnrollmentPeriodQuery === ''
    ) {
      return findEnrollmentPeriodIdOrDefault(
        enrollmentPeriods,
        enrollmentPeriodLocal
      );
    }

    return enrollmentPeriods.length > 0 ? enrollmentPeriods[0].id : null;
  }, [enrollmentPeriods, openEnrollmentPeriodQuery, enrollmentPeriodLocal]);

  return {
    selectedEnrollmentPeriodId,
    setOpenEnrollmentPeriodQuery,
    setEnrollmentPeriodLocal,
    enrollmentPeriods,
  };
}
